import { apiClient, parseJwt } from '../utils';
import { GLOBAL_DEPTH, GET_STUDENT_STATE_RULES_ENDPOINT } from '../constants';
import { StateRules, StateRulesField } from '../../../interfaces/api';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { getSchoolProvidedStudentMessage } from '../../../services/mockService';

export interface Indicator {
  key: string; // same as id
  value: string; // the Display Name
  studentName: string;
  // name: string; // same as value
  depth: number; // Used for DropDown item indent
  hidden: boolean; // hide in DropDown list
  Header: string; // same as value
  accessor: string; // same as id
  parentKey: string;
  children: string[] | null;
  description: string;
  editable: boolean;
  studentLongDescription: string;
  studentShortDescription: string;
  childFieldCategories?: string[];
  category?: string;
}

// this makes DropDownItem[] compatible list allowing for indentation and hiding items beyond GLOBAL_DEPTH
const flatStateRules: { [key: string]: Indicator } = {};
const flatten = (items: StateRulesField[], parentKey: string) => {
  items.forEach((item: StateRulesField) => {
    const key: string = item.id;
    const value: string = item.staffName || item.id;
    const studentName: string = item.studentName || value;
    const description: string =
      item.staffDescription && item.staffDescription !== '' ? item.staffDescription : value;
    if (item.fields) {
      const expandable: boolean = item.fields.length > 1;
      flatStateRules[key] = {
        key: key,
        value: value,
        studentName: studentName,
        depth: item.depth,
        hidden: item.depth >= GLOBAL_DEPTH && !expandable,
        Header: value,
        accessor: `results.${key}`,
        parentKey: parentKey,
        children: [],
        description: item.staffDescription,
        editable: item.editable || false,
        studentLongDescription: item.studentLongDescription,
        studentShortDescription: item.studentShortDescription,
        childFieldCategories: item.childFieldCategories,
        category: item.category,
      };
      item.fields?.forEach((field: StateRulesField) => {
        const child = field.id;
        flatStateRules[key].children?.push(child);
        return flatten([field], key);
      });
    } else {
      flatStateRules[key] = {
        key: key,
        value: value,
        studentName: studentName,
        depth: item.depth,
        hidden: true, // item.depth >= GLOBAL_DEPTH,
        Header: value,
        accessor: `results.${key}`,
        parentKey: parentKey,
        description: item.staffDescription,
        children: null,
        editable: item.editable || false,
        studentLongDescription: item.studentLongDescription,
        studentShortDescription: item.studentShortDescription,
        childFieldCategories: item.childFieldCategories,
        category: item.category,
      };
    }
  });

  return flatStateRules;
};

// this makes the table compatible specifications to allow mapping of the StudentDetails API response using the field IDs
export const studentTableIndicatorColumns = (indicatorsIn: object, inKey: unknown) => {
  const indicators = Object.values(indicatorsIn);
  const currentIndicatorsColumns: Indicator[] = [];
  indicators.forEach((indicator: Indicator) => {
    if (indicator.key === inKey) {
      currentIndicatorsColumns.push(indicator);
    } else if (indicator.parentKey === inKey) {
      currentIndicatorsColumns.push(indicator);
    }
  });

  return currentIndicatorsColumns;
};

// this requires the state and class year
export const getIndicators = async (jwt: string) => {
  const { classYear, state } = parseJwt(jwt);

  let useMock = '';
  if (jwt === '') {
    useMock = '?useMock=true';
  }

  const url = `${GET_STUDENT_STATE_RULES_ENDPOINT}/${state}/${classYear}`;

  const headers: AxiosRequestHeaders = {
    authorization: `Bearer ${jwt}`,
  };

  const config: AxiosRequestConfig = {
    url: url,
    method: 'GET',
    headers: headers,
  };

  const stateRules: StateRules = await apiClient(config);

  const indicatorsMap: { [key: string]: Indicator } = flatten(stateRules.fields, '');
  const getStateName = stateRules?.stateName;
  const topLevelRequirementRule = stateRules?.topLevelRequirementRule;
  const studentMainDescription = stateRules?.fields[0].studentLongDescription;
  const schoolProvidedStudentMessage =
    stateRules?.schoolProvidedStudentMessage ||
    getSchoolProvidedStudentMessage(state.toLowerCase());
  return {
    indicatorsMap,
    getStateName,
    topLevelRequirementRule,
    studentMainDescription,
    schoolProvidedStudentMessage,
  };
};
