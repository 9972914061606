const css = `.sri-components-indicatorTaskData-indicator-task {
  box-sizing: border-box;
  width: 47%;
  height: auto;
  margin-top: 24px;
  background: #f6f6fa;
  border: 1px solid #c4c9ca;
  border-radius: 4px;
  font-family: "Ubuntu";
  font-style: normal;
  margin-right: 22px;
  padding-left: 20px;
  padding-right: 10px;
}
.sri-components-indicatorTaskData-indicator-task.right {
  float: right;
  clear: right;
}
.sri-components-indicatorTaskData-indicator-task.left {
  display: inline-block;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header {
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-indicator-tier {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 10px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-indicator-level-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.576px;
  color: #000000;
  flex-grow: 1;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-status {
  height: 0px;
  margin-top: -26px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-status.nodata .label-name {
  font-weight: 400 !important;
  color: #6c7584 !important;
  margin-right: 28px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-status .labeled-icon {
  justify-content: left !important;
  min-width: 127px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-status .label-name {
  width: 90%;
  height: 21px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  mix-blend-mode: normal;
  flex: none;
  order: 2;
  flex-grow: 0;
}

@media screen and (max-width: 950px) {
  .sri-components-indicatorTaskData-indicator-task {
    width: 100%;
  }
  .sri-components-indicatorTaskData-indicator-task.right {
    float: none;
    clear: none;
  }
}
@media screen and (max-width: 1080px) and (min-width: 950px) {
  .sri-containers-page-all-data .student-component-requirementInfo-bar-inner {
    height: 100px;
  }
}
@media screen and (max-width: 1522px) and (min-width: 1080px) {
  .sri-containers-page-all-data .student-component-requirementInfo-bar-inner {
    height: 70px;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdHVkZW50L0NvbXBvbmVudHMvSW5kaWNhdG9yVGFza0RhdGEiLCJzb3VyY2VzIjpbIkluZGljYXRvclRhc2tEYXRhLnNjc3MiLCIuLi8uLi9kZWZpbml0aW9ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFFRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0EsT0MzQkk7RUQ0Qko7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVFO0VBQ0U7RUFDQTtFQUNBOztBQUdKO0VBQ0U7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EsT0NuRWE7RURvRWI7RUFDQTtFQUNBO0VBQ0E7OztBQUtOO0VBQ0U7SUFDRTs7RUFDQTtJQUNFO0lBQ0E7OztBQUtOO0VBQ0U7SUFDRTs7O0FBR0o7RUFDRTtJQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
