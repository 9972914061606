import './IndicatorTaskData.scss';
import React, { ReactNode } from 'react';
import { LabeledIcon } from '../../../staff/components/LabeledIcon';
import { IconSvg } from '../../../staff/components/Icon';
import { IndicatorStatus, INDICATORSTATUSES } from '../../../../interfaces/api';

export interface Props {
  title: string;
  status: IndicatorStatus;
  taskSide?: 'right' | 'left';
  labelSide?: 'right' | 'left';
  children?: ReactNode;
}

export const IndicatorTaskData = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (IndicatorTaskData as React.FC).displayName = 'IndicatorTaskData';
  const { title, status, taskSide = 'left', labelSide = 'right', children } = props;
  const statusLabel = INDICATORSTATUSES.find(
    (indicatorStatus) => indicatorStatus.key === status
  )?.value;
  return (
    <div className={`sri-components-indicatorTaskData-indicator-task ${taskSide}`}>
      <div className="sri-components-indicatorTaskData-header">
        <div className="sri-components-indicatorTaskData-indicator-level-title">{title}</div>
        <div className={`sri-components-indicatorTaskData-status ${status?.toLowerCase()}`}>
          <LabeledIcon
            iconName={status as IconSvg}
            label={statusLabel}
            labelSide={labelSide}
            hideLabelIcon={status === IndicatorStatus.NO_DATA}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
