/* eslint-disable react/prop-types */
import './App.scss';
import { Page, Props as PageProps } from './containers/Page';
import { Widget, Props as WidgetProps } from './containers/Widget';
import { APP_DISPLAY_NAME } from './constants';
import { getUserContextProps } from './utils';
import React from 'react';

interface AppProps {
  widget?: boolean;
  myPlannerUrl?: string;
  apiUrl?: string;
  jwt?: string;
}

const pageProps: PageProps = {
  appDisplayName: APP_DISPLAY_NAME,
  ...getUserContextProps(),
} as PageProps;
const widgetProps: WidgetProps = {
  appDisplayName: APP_DISPLAY_NAME,
  ...getUserContextProps(),
} as WidgetProps;

const App = (props: AppProps): JSX.Element => {
  (App as React.FC).displayName = 'SRI App Container';

  const { widget, myPlannerUrl, apiUrl, jwt } = props;

  // TODO:: remove this once APIs, Backend Proxy, and Real Data is ready
  const isDev =
    location.port === '4214' ||
    location.port === '4204' ||
    location.hostname === 'succeed.local.naviance.com' ||
    location.hostname.endsWith('.dev.cclr.naviance.com');
  const isQA = location.hostname === 'tf-succeed-56-qa.mango.naviance.com';
  if (isDev || isQA) {
    if (!window.SRI_STUDENT_UI_CONTEXT) {
      window.SRI_STUDENT_UI_CONTEXT = { props: {} } as Window['SRI_STUDENT_UI_CONTEXT'];
    }
    if (!pageProps.state) {
      // State Overrides
      window.SRI_STUDENT_UI_CONTEXT.state =
        new URLSearchParams(location.search).get('state') || 'oh';
    }
    if (!widgetProps.state) {
      // State Overrides
      window.SRI_STUDENT_UI_CONTEXT.state =
        new URLSearchParams(location.search).get('state') || 'oh';
    }

    window.SRI_STUDENT_UI_CONTEXT.mockStudents =
      new URLSearchParams(location.search).get('mockStudents') || 'false';
  }

  return (
    <React.Fragment>
      {widget ? (
        <Widget myPlannerUrl={myPlannerUrl || ''} apiUrl={apiUrl || ''} jwt={jwt} />
      ) : (
        <Page apiUrl={apiUrl || ''} jwt={jwt} />
      )}
    </React.Fragment>
  );
};

export default App;
