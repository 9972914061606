import './widget.scss';
import React, { useEffect, useState } from 'react';
import { API_ERROR } from '../../constants';
import { Indicator, getIndicators, getStudentDetails } from '../../api';
import { RequirementInfo } from '../../Components/RequirementInfo';
import { IndicatorStatus, INDICATORSTATUSES } from '../../../../interfaces/api';
import { LabeledIcon } from '../../../staff/components/LabeledIcon';
import { IconSvg } from '../../../staff/components/Icon';
import { Loader, LoaderItem } from '../../../staff/components/Loader';
import { EmptyDetails } from '../../Components/EmptyDetails';

export interface Props {
  appDisplayName?: string;
  myPlannerUrl: string;
  state?: string;
  apiUrl: string;
  jwt?: string;
}

interface Level2Indicator {
  studentName: string;
  studentStatus: IndicatorStatus;
}

interface StudentIndicatorDetails {
  topLevelStudentName: string;
  topLevelStudentStatus: IndicatorStatus;
  topLevelStudentStatusLabel: string;
  studentShortDescription: string;
  level2indicatorList: Array<Level2Indicator>;
}

export const Widget = (props: Props): JSX.Element => {
  (Widget as React.FC).displayName = 'Widget';

  const { myPlannerUrl, apiUrl, jwt } = props;

  if (apiUrl) {
    (window as unknown as any).SRI_STUDENT_UI_CONTEXT = { API_URL: apiUrl };
  }
  const emptyIndicators: Indicator[] = [];
  const emptyIndicatorDetails: StudentIndicatorDetails = {
    topLevelStudentName: '',
    topLevelStudentStatus: IndicatorStatus.NO_DATA,
    topLevelStudentStatusLabel: '',
    studentShortDescription: '',
    level2indicatorList: [],
  };

  const [{ indicators, indicatorsMap }, setIndicators] = useState<{
    indicators: Indicator[];
    indicatorsMap: { [key: string]: Indicator };
  }>({
    indicators: emptyIndicators,
    indicatorsMap: {},
  });
  const [studentDetailsRetrieved, setStudentDetailsRetrieved] = useState<boolean>(false);
  const [indicatorDetails, setIndicatorDetails] =
    useState<StudentIndicatorDetails>(emptyIndicatorDetails);
  const [loader, setLoader] = useState<LoaderItem>({
    size: 'medium',
    loading: false,
  });
  const [loaderTimedOut, setLoaderTimedOut] = useState<boolean>(false);
  const [invalidData, setInvalidData] = useState<boolean>(false);

  const showLoader = (loaderOps: LoaderItem) => {
    setLoader({
      ...loader,
      ...loaderOps,
    });
  };

  const prepareStudentIndicatorDetails = (studentResults: { [key: string]: IndicatorStatus }) => {
    // Prepare level 1 indicator details
    const topLevelIndicatorDetails: Indicator | undefined = indicators.find(
      (item) => item.depth === 0
    );
    if (topLevelIndicatorDetails) {
      emptyIndicatorDetails.topLevelStudentName = topLevelIndicatorDetails.studentName;
      emptyIndicatorDetails.topLevelStudentStatus =
        studentResults[topLevelIndicatorDetails['key']] || IndicatorStatus.NO_DATA;
      emptyIndicatorDetails.studentShortDescription =
        topLevelIndicatorDetails.studentShortDescription;
      emptyIndicatorDetails.topLevelStudentStatusLabel =
        INDICATORSTATUSES.find(
          (indicatorStatus) => indicatorStatus.key === emptyIndicatorDetails.topLevelStudentStatus
        )?.value || '';
    }
    // Prepare level 2 indicator list
    const level2IndicatorList: Array<Indicator> = indicators.filter((item) => item.depth === 1);
    if (Array.isArray(level2IndicatorList)) {
      const indicatorStatusList = [];
      for (const indicator of level2IndicatorList) {
        indicatorStatusList.push({
          studentName: indicator.studentName,
          studentStatus: studentResults[indicator['key']],
        });
      }

      // sort by based on status ["Met", "InProgress", "NotMet","NoData"]
      for (const status of INDICATORSTATUSES) {
        emptyIndicatorDetails.level2indicatorList = [
          ...emptyIndicatorDetails.level2indicatorList,
          ...indicatorStatusList
            .filter((indicator) => indicator.studentStatus === status.key)
            .sort((a: Level2Indicator, b: Level2Indicator) =>
              a.studentName.toLowerCase().localeCompare(b.studentName.toLowerCase())
            ),
        ];
      }

      // Limit up to 3 (Top 3 level 2 indicators)
      emptyIndicatorDetails.level2indicatorList = emptyIndicatorDetails.level2indicatorList.slice(
        0,
        3
      );
    }

    setIndicatorDetails(emptyIndicatorDetails);
    setStudentDetailsRetrieved(true);
    showLoader({ loading: false });
  };

  const retrieveStudentDetails = async () => {
    const singleStudentDetails = await getStudentDetails((jwt as unknown as string) || '');
    if (singleStudentDetails && (singleStudentDetails as unknown as any).status === API_ERROR) {
      setInvalidData(true);
    } else {
      prepareStudentIndicatorDetails(singleStudentDetails?.results as unknown as any);
    }
  };

  const getAndParseIndicators = async () => {
    const getData = await getIndicators(jwt as unknown as string);
    if (getData && (getData as unknown as any).status === API_ERROR) {
      setInvalidData(true);
    } else {
      const indicatorsMap: { [key: string]: Indicator } = getData?.indicatorsMap;
      const indicatorsArray: Indicator[] = Object.values(indicatorsMap);
      setIndicators({ indicators: indicatorsArray, indicatorsMap });
    }
  };

  const handleTimeout = () => {
    setLoaderTimedOut(true);
  };

  useEffect(() => {
    showLoader({ loading: true });
    if (indicators?.length < 1) {
      // only retrieve indicators once
      getAndParseIndicators();
    } else if (indicators[0]) {
      retrieveStudentDetails();
    }
  }, [indicators]);

  const validDetails =
    studentDetailsRetrieved && indicatorDetails && indicatorDetails.topLevelStudentStatus;
  const invalidDetails = invalidData;

  return (
    <>
      {loader.loading && (
        <div className="sri-widget-loader">
          <Loader size={loader.size} timeout={loader.timeout} onTimeout={handleTimeout} />
        </div>
      )}
      {(loaderTimedOut || invalidDetails) && <EmptyDetails widget={true} />}
      {validDetails && (
        <div className="sri-widget">
          <div className="sri-widget-inner">
            <div className="sri-widget-header">
              <a href={myPlannerUrl}>{indicatorDetails.topLevelStudentName}</a>
              <div
                className={`sri-widget-main-icon ${indicatorDetails.topLevelStudentStatus?.toLowerCase()}`}
              >
                <LabeledIcon
                  iconName={indicatorDetails.topLevelStudentStatus as IconSvg}
                  label={indicatorDetails.topLevelStudentStatusLabel}
                  labelSide={'right'}
                  hideLabelIcon={indicatorDetails.topLevelStudentStatus === IndicatorStatus.NO_DATA}
                />
              </div>
            </div>
            <div className="sri-widget-text">{indicatorDetails.studentShortDescription}</div>
            <div className="sri-widget-indicators">
              {indicatorDetails.level2indicatorList.map(
                (indicator: Level2Indicator, index: number) => (
                  <RequirementInfo
                    key={index}
                    title={indicator.studentName}
                    status={indicator.studentStatus}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
