const css = `.sri-components-indicatorTaskData-indicator-task {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
}
.sri-components-indicatorTaskData-indicator-task.with-children > label {
  cursor: pointer;
}
.sri-components-indicatorTaskData-indicator-task.depth-2 > label > .sri-components-indicatorTaskData-header {
  background: white;
  padding: 20px 20px 20px 20px;
}
.sri-components-indicatorTaskData-indicator-task.with-children.depth-1 > label > .sri-components-indicatorTaskData-header {
  border-radius: 0px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 10px;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 > label > .sri-components-indicatorTaskData-header {
  padding: 6px 20px;
  min-height: 80px;
  border: 0px;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-header-title {
  font-size: 20px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header {
  padding: 24px 70px 24px 38px;
  background: #f6f6fa;
  border-radius: 0px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-header-title {
  font-size: 27px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status {
  width: auto;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .labeled-icon {
  justify-content: end;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .labeled-icon .label-name {
  font-size: 22px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .labeled-icon .sri-icon-inner {
  width: 40px;
  height: 40px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > label > .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .labeled-icon .sri-icon-inner svg {
  width: 40px;
  height: 40px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header {
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 11px 10px 11px 20px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status {
  width: 120px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .labeled-icon {
  justify-content: center;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .label-name {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-status .sri-components-indicatorTaskData-requirements {
  font-weight: 400;
  font-size: 14px;
  color: #5e6671;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-header .sri-components-indicatorTaskData-header-title {
  flex: 1;
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  font-family: "Ubuntu";
}
.sri-components-indicatorTaskData-indicator-task .accordion__arrow {
  width: 24px;
  margin-right: 8px;
  transform: rotateZ(-90deg);
  height: 24px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdHVkZW50L0NvbXBvbmVudHMvU3R1ZGVudEluZGljYXRvckhlYWRlciIsInNvdXJjZXMiOlsiU3R1ZGVudEluZGljYXRvckhlYWRlci5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUlBO0VBQ0U7RUFDQTs7QUFNQTtFQUNFO0VBQ0E7RUFDQTs7QUFNSjtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQU1KO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFDQTtFQUNFOztBQUNBO0VBQ0U7O0FBRUY7RUFDRTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFPWjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBQ0E7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBLE9DMUVXOztBRDRFYjtFQUNFO0VBQ0E7RUFDQTs7QUFHSjtFQUNFO0VBQ0EsT0M3RU07RUQ4RU47RUFDQTtFQUNBOztBQUdKO0VBQ0U7RUFDQTtFQUNBO0VBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
