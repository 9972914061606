import { School, ProvisionStatus } from '../../../interfaces/api';

export const school_100001USPU: School = {
  id: '100001USPU', // IDs here must match IDs in District API
  name: 'East Oak High School',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  counselors: [
    {
      id: '100001',
      name: 'Juan Enders',
    },
    {
      id: '100002',
      name: 'Phillis Tsal',
    },
  ],
  classYears: [2023, 2024, 2025],
};

export const school_100002USPU: School = {
  id: '100002USPU', // IDs here must match IDs in District API
  name: 'West Oak High School',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  counselors: [
    {
      id: '100004',
      name: 'Lily Lavender',
    },
    {
      id: '100005',
      name: 'Victor Violet',
    },
    {
      id: '100006',
      name: 'Allison Amber',
    },
  ],
  classYears: [2023, 2025],
};

export const school_100003USPU: School = {
  id: '100003USPU', // IDs here must match IDs in District API
  name: 'North Elm HS',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  counselors: [
    {
      id: '100007',
      name: 'Jane Way',
    },
    {
      id: '100008',
      name: 'Billy Joe',
    },
  ],
  classYears: [2023, 2024, 2025],
};

export const school_100004USPU: School = {
  id: '100004USPU', // IDs here must match IDs in District API
  name: 'South Elm HS',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  counselors: [
    {
      id: '100009',
      name: 'Lilith Lilac',
    },
    {
      id: '100010',
      name: 'Val Merkill',
    },
    {
      id: '100011',
      name: 'Stacy Macee',
    },
  ],
  classYears: [2023, 2025],
};

export const school_100005USPU: School = {
  id: '100005USPU', // IDs here must match IDs in District API
  name: 'Weeping Willow HS',
  provisionedStatus: ProvisionStatus.IN_PROGRESS,
  hasStudentReadinessData: false,
  counselors: [
    {
      id: '1000012',
      name: 'Jack Ryan',
    },
  ],
  classYears: [2023, 2024],
};

export const school_100006USPU: School = {
  id: '100006USPU', // IDs here must match IDs in District API
  name: 'Sycamore HS',
  provisionedStatus: ProvisionStatus.NOT_REQUESTED,
  hasStudentReadinessData: false,
  counselors: [
    {
      id: '1000013',
      name: 'Enola Holmes',
    },
  ],
  classYears: [2023, 2024],
};
