import './requirementInfo.scss';
import React from 'react';
import { LabeledIcon } from '../../../staff/components/LabeledIcon';
import { IconSvg } from '../../../staff/components/Icon';
import { IndicatorStatus, INDICATORSTATUSES } from '../../../../interfaces/api';

export interface Props {
  title?: string;
  status: IndicatorStatus;
  labelSide?: 'right' | 'left';
}

export const RequirementInfo = (props: Props): JSX.Element => {
  (RequirementInfo as React.FC).displayName = 'RequirementInfo';
  const { title, status, labelSide = 'right' } = props;
  const statusLabel = INDICATORSTATUSES.find(
    (indicatorStatus) => indicatorStatus.key === status
  )?.value;

  return (
    <div className="student-containers-requirementInfo-show-data">
      <div className="student-component-requirementInfo-main">
        <div className="student-component-requirementInfo-bar-inner">
          <div className={`student-component-requirementInfo-bar ${status?.toLowerCase()}`}></div>
          <div className="student-component-requirementInfo-frame">
            <div className="student-component-requirementInfo-title">
              {title ? title : statusLabel}
            </div>
          </div>
          <div className={`student-component-requirementInfo-status ${status?.toLowerCase()}`}>
            <LabeledIcon
              iconName={status as IconSvg}
              label={statusLabel}
              labelSide={labelSide}
              hideLabelIcon={status === IndicatorStatus.NO_DATA}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
