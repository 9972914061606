export const APP_DISPLAY_NAME = 'Student Readiness Indicators';

// for special tweaks to make local standalone not complain
export const IS_STANDALONE_DEV = location.hostname === 'sri-student.local.naviance.com';
export const IS_NAV_STUDENT_DEV = location.host === 'localhost:3000';
export const IS_LOCAL_DEV = location.hostname.indexOf('local') > 0;

export const ERROR_MESSAGE_TEXT =
  'Something went wrong. Try again. If the problem persists, please contact support.';
export const STATIC_STUDENT_SCHOOL_MESSAGE = `Use the information on this page to keep track of your progress as you work towards your
future goals. School or district staff will update the requirements based on the
information they have available to them.`;
export const SYSTEM_ERROR_TIMEOUT = 15000;
export const TOASTER_DEFAULT_TIMEOUT = 2000;

export const GLOBAL_DEPTH = 2; // how to nest the indicators

export enum STATUS {
  ALL = 'all',
  MET = 'Met',
  IN_PROGRESS = 'InProgress',
  NOT_MET = 'NotMet',
  NO_DATA = 'NoData',
}

export const STATUSES = [
  { key: STATUS.MET, value: 'Met' },
  { key: STATUS.IN_PROGRESS, value: 'In Progress' },
  { key: STATUS.NOT_MET, value: 'Not Met' },
  { key: STATUS.NO_DATA, value: 'No Information' },
];
