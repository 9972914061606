import { Aggregate, AggregateResults } from '../interfaces/api/aggregate';

import {
  aggregateOh,
  aggregateTx,
  baseStudentOh,
  baseStudentTx,
  district_100001DUS,
  district_100002DUS,
  district_100003DUS,
  district_100004DUS,
  school_100001USPU,
  school_100002USPU,
  school_100003USPU,
  school_100004USPU,
  school_100005USPU,
  school_100006USPU,
} from '../lambda/api/mocks';

import { District, IndicatorStatus, School, Student } from '../interfaces/api';

const DEFAULT_DISTRICT = district_100001DUS; // use default district for testing in Naviance Local/QA/Prod

export const getMockByDistrictId = (districtId: string) => {
  if (districtId === 'UNACTIVATED-DIST') return;

  const mockDistricts = new Map<string, District>([
    ['100001DUS', district_100001DUS],
    ['100002DUS', district_100002DUS],
    ['100003DUS', district_100003DUS],
    ['100004DUS', district_100004DUS],
  ]);
  return mockDistricts.get(districtId) || DEFAULT_DISTRICT;
};

const DEFAULT_SCHOOL = school_100001USPU; // use default school for testing in Naviance Local/QA/Prod
export const getMockSchoolById = (schoolId: string): School => {
  const mockSchools: Record<string, School> = {
    '100001USPU': school_100001USPU,
    '100002USPU': school_100002USPU,
    '100003USPU': school_100003USPU,
    '100004USPU': school_100004USPU,
    '100005USPU': school_100005USPU,
    '100006USPU': school_100006USPU,
  };

  return mockSchools[schoolId] || DEFAULT_SCHOOL;
};

export const aggregatesByState = new Map<string, Aggregate>([
  ['oh', aggregateOh],
  ['tx', aggregateTx],
]);

export const baseStudentByState = new Map<string, Student>([
  ['oh', baseStudentOh],
  ['tx', baseStudentTx],
]);

export const statusArray = [
  IndicatorStatus.MET,
  IndicatorStatus.NOT_MET,
  IndicatorStatus.IN_PROGRESS,
  IndicatorStatus.NO_DATA,
];

export const mockNames = {
  fns1: ['Al', 'Bo', 'Cri', 'Da', 'Eva', 'Fru', 'Glo', 'Hy', 'Ilu'],
  fns2: ['am', 'be', 'co', 'da', 'em', 'fo', 'gi', 'ha', 'in'],
  fns3: ['aby', 'by', 'che', 'dy', 'er', 'fun', 'g', 'hur', 'in'],
  lns1: ['Ar', 'Bri', 'Clo', 'De', 'En', 'Fo', 'Ga', 'Hi', 'Int'],
  lns2: ['an', 'ba', 'ci', 'du', 'en', 'fe', 'go', 'hu', 'im'],
  lns3: ['art', 'bo', 'con', 'dot', 'end', 'fin', 'gor', 'hy', 'ill'],
};

export const aRand = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const generateStudent = (
  schoolId: string | null,
  state: string,
  classYear: number
): Student => {
  const baseStudent = baseStudentByState.get(state);

  const newStudent: Student = JSON.parse(JSON.stringify(baseStudent));

  let school = getMockSchoolById(schoolId || '100001USPU');
  if (!school) {
    school = school_100001USPU; // use default school for testing in Naviance Local/QA/Prod
  }

  // get counselors and class years from district
  newStudent.id = '1012345';
  let counselors = school.counselors;
  counselors = [...counselors, ...counselors, ...counselors];
  let counselorId = counselors[aRand(counselors.length + 1)]?.id;
  counselorId = counselorId !== undefined ? counselorId : '0';
  newStudent.counselorId = counselorId;
  newStudent.classYear = classYear;
  newStudent.firstName = `${mockNames.fns1[aRand(9)]}${mockNames.fns2[aRand(9)]}${
    mockNames.fns3[aRand(9)]
  }`;
  newStudent.lastName = `${mockNames.lns1[aRand(9)]}${mockNames.lns2[aRand(9)]}${
    mockNames.lns3[aRand(9)]
  }`;
  newStudent.dateOfBirth = `2010-02-22`;
  newStudent.ethnicity = `OT`;
  newStudent.stateStudentId = `StateStudentId`;
  Object.keys(newStudent.results).forEach((key) => {
    newStudent.results[key] = statusArray[aRand(statusArray.length)];
  });

  return newStudent;
};

export const generateStudents = (
  schoolId: string | null,
  state: string,
  classYear: number,
  numStudents: number
): Student[] => {
  const students: Student[] = [];
  for (let i = 0; i < numStudents; i++) {
    const newStudent = generateStudent(schoolId, state, classYear);
    newStudent.id = `${parseInt(newStudent.id) - i}`;
    students.push(newStudent);
  }
  return students;
};

export const generateStudentMessages = (
  classYears: number[],
  state: string
): [number, string][] => {
  const schoolProvidedStudentMessage = getSchoolProvidedStudentMessage(state);
  const studentMessages: [number, string][] = [];
  if (schoolProvidedStudentMessage) {
    classYears?.forEach((year) => {
      studentMessages.push([year, schoolProvidedStudentMessage]);
    });
  }
  return studentMessages;
};
export const getSchoolProvidedStudentMessage = (state = 'oh'): string | undefined => {
  if (state === 'tx') {
    return (
      '<b>Information From My School</b><BR>' +
      'AP/IB indicator - only one (AP or IB) must be earned in order to meet the requirement.<BR>' +
      'Industry Certification indicator - you must submit the appropriate paperwork before your Junior year begins.<BR>' +
      'Military Readiness Indicator - a local ROTC member can be contacted here to help answer any questions you may have regarding joining the military.<BR>' +
      'Have any questions? Please contact your school counselor.'
    );
  }
  if (state === 'oh') {
    return (
      '<b>Information From My School</b><BR>' +
      'Course Requirements:<UL>' +
      '<LI>Our district requires that you complete 21 units in state-specified subjects and complete state-specified test.</LI></UL>' +
      'Competency:<UL>' +
      '<LI>More information on the Algebra l and English ll tests can be found here.</LI>' +
      '<LI>A local military member can help answer any questions you may have regarding joining the military. They can be contacted here.</LI></UL>' +
      'Readiness:<UL>' +
      '<LI>More information on the Seal of Biliteracy can be found here.</LI></UL>'
    );
  }
  return undefined;
};

export function generateMockAggregateResults(
  counselorId: string | null,
  schoolId: string | null,
  districtId: string | null,
  classYear: number,
  results: AggregateResults
) {
  // generate consistent mock data response based on the district/school/counselor/classYear and the index
  const counselorIdMod = (counselorId?.charCodeAt(5) || 0) % 2 === 0 ? 3 : -3;
  const schoolIdMod: number = (schoolId?.charCodeAt(5) || 0) % 2 === 0 ? 4 : -4;
  const schoolMod: number = schoolId
    ? schoolIdMod + 115 - (schoolId?.charCodeAt(5) || 115)
    : 115 - (schoolId?.charCodeAt(5) || 115);
  const districtMod: number = 110 - (districtId?.charCodeAt(5) || 110);
  const classYearMod: number = 2 * (classYear - 2022);
  Object.keys(results).forEach((key, index) => {
    const evenIndex: boolean = index % 2 === 0;
    const indexMod: number = evenIndex ? index : -1 * index;
    const mod =
      (!schoolId ? districtMod : 0) + schoolMod + counselorIdMod + indexMod + classYearMod;
    results[key] = results[key] - mod;
  });
}
