const css = `ul {
  margin-right: 15px;
}

.container {
  background-color: black;
  border: 2px solid #42d6ca;
  color: #42d6ca;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13.3333px;
  font-weight: 400;
  left: 30px;
  position: absolute;
  user-select: none;
}
.container.header {
  top: 50px;
}
.container.chooser {
  max-height: 600px;
  top: 120px;
  z-index: 9999;
}
.container.chooser.icons {
  overflow-y: scroll;
}
.container.chooser img {
  -webkit-user-drag: none;
  cursor: pointer;
  display: inline-block;
  margin: auto;
  object-fit: cover;
  vertical-align: middle;
}
.container.chooser img.background {
  width: 200px;
}
.container.chooser img.icon {
  display: block;
  margin-top: 25px;
}
.container div {
  margin: 10px;
}
.container hr {
  visibility: hidden;
}
.container button {
  background: none;
  border: 2px solid #42d6ca;
  box-sizing: border-box;
  color: #42d6ca;
  height: 30px;
  margin: 10px;
  position: relative;
  width: 100px;
  transition: all 0.5s ease;
}
.container button:before {
  content: " ";
  border: 2px transparent solid;
  box-sizing: border-box;
  height: 50px;
  left: 50%;
  position: absolute;
  top: -45%;
  transform: translateX(-50%);
  transition: all 0.7s ease;
  width: 120px;
}
.container button:focus {
  outline: none;
  opacity: 0.2;
}
.container button:hover {
  background-color: transparent;
  border-radius: 20px;
}
.container button:hover:before {
  border-radius: 30px;
  border: 1px #42d6ca solid;
}

.arrow {
  border: solid #42d6ca;
  border-width: 0 10px 10px 0;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  vertical-align: middle;
}
.arrow.down {
  transform: rotate(45deg);
}
.arrow.left {
  transform: rotate(135deg);
}
.arrow.right {
  transform: rotate(-45deg);
}
.arrow.up {
  transform: rotate(-135deg);
}

.thing {
  -webkit-user-drag: none;
  position: absolute;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdHVkZW50Iiwic291cmNlcyI6WyJBcHAuc2NzcyIsImRlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0EsT0NOSztFRE9MO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUVGO0VBQ0U7RUFDQTs7QUFJTjtFQUNFOztBQUVGO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQSxPQ2xERztFRG1ESDtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0o7RUFDRTtFQUNBOztBQUVGO0VBQ0U7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7OztBQUtOO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7OztBQUlKO0VBQ0U7RUFDQSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
