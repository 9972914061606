import { IS_STANDALONE_DEV } from './common';

export const API_ERROR = 'API_ERROR';
export const BASE_API_URL = () => {
  return (window as unknown as any).SRI_STUDENT_UI_CONTEXT?.API_URL || null;
};

export const GET_USER_DETAILS = '/user/info';

// for student view
export const BASE_SRI_STUDENT_PATH =
  IS_STANDALONE_DEV || location.hostname.match('sristudent.*.dev.cclr.naviance.com')
    ? ''
    : BASE_API_URL();
export const GET_STUDENT_STATE_RULES_ENDPOINT = `/state`; // `/rules/school`; <-- use this new API after merging
export const GET_STUDENT_STUDENT_DETAILS_ENDPOINT = `/schools`;
