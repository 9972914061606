const css = `.sri-empty-details {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  height: auto;
  margin: 10px;
  font-family: Ubuntu, Open Sans, sans-serif;
  font-style: normal;
  font-size: 16px;
  color: black;
  line-height: 27px;
}
.sri-empty-details .sri-missing-documents {
  height: 57px;
  width: 48px;
}
.sri-empty-details .sri-empty-details-message {
  margin: auto 0 auto 30px;
}

.sri-empty-details-page {
  display: flex;
  justify-content: center;
  line-height: 27px;
  background-color: white;
  margin: 0;
  padding: 20px 0 30px 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvc2hvYWliL3dvcmsvcG93ZXJzY2hvb2wvc3R1ZGVudC1yZWFkaW5lc3MtaW5kaWNhdG9ycy9mcm9udGVuZC9zdHVkZW50L0NvbXBvbmVudHMvRW1wdHlEZXRhaWxzIiwic291cmNlcyI6WyJlbXB0eURldGFpbHMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFOzs7QUFJSjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSJ9 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
