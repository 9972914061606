import axios, { AxiosRequestConfig } from 'axios';
import { BASE_API_URL, IS_STANDALONE_DEV, API_ERROR } from '../constants';
import { getUserContextMockStudents } from './context';

// UI code expects to find `config.json` containing the `apiUrl` for use in connecting to the backend API.
// These are automatically set in the deployment process.
// When running locally the config.json is proxied via /esbuild.ts
const getApiUrl = async () => {
  let devLocalPrefix = '';
  if (IS_STANDALONE_DEV) {
    // use full local url to avoid relative/non-dev issues
    devLocalPrefix = `https://${location.hostname}${location.port ? `:${location.port}` : ':4212'}`;
  } else if (location.host === 'localhost:3000') {
    devLocalPrefix = `http://${location.hostname}:4204`;
  }
  const { data: config } = await axios.get(`${devLocalPrefix}/config.json`);
  return `${config.apiUrl}`;
};

export const apiClient = async (configs: AxiosRequestConfig) => {
  if (getUserContextMockStudents() === 'false' && configs.url) {
    configs.url += configs.url.indexOf('?') == -1 ? '?' : '&';
    configs.url += 'useMock=false';
  }
  // use the embedding app's window context global for the API_URL if it exists
  let apiUrlResult = BASE_API_URL();
  if (!apiUrlResult || apiUrlResult === undefined) {
    apiUrlResult = await getApiUrl();
  }
  apiUrlResult = !apiUrlResult || apiUrlResult === 'undefined' ? null : apiUrlResult;

  const useAxios = axios.create({
    baseURL: apiUrlResult,
    withCredentials: true,
    headers: {
      'disable-rewrite': '1',
      'header-meta': '1',
    },
  });

  return await useAxios
    .request(configs)
    .then((response) => response.data)
    .catch(() => {
      return Promise.resolve({ status: API_ERROR });
    });
};
